import React, { useRef, useState } from 'react';
import './App.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Status from './pages/status';

firebase.initializeApp({
  // your config
  apiKey: "AIzaSyA3DmAAUfZpe6-F1qKanyy64fLlEyqqJNY",
  authDomain: "fullesekmi.firebaseapp.com",
  projectId: "fullesekmi",
  storageBucket: "fullesekmi.appspot.com",
  messagingSenderId: "345642808084",
  appId: "1:345642808084:web:1e548a0dfcb73431e64801",
  measurementId: "G-72T75Q2X96"
})

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();


function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <h1>⛽ fullesekmi.com </h1>
        <SignOut/>
      </header>
      <section>
        <Router>
          <Routes>
              <Route exact path='/' element={user ? <ChatRoom /> : <SignIn />} />
              <Route path='/status' element={<Status/>} />
          </Routes>
        </Router>
      </section>

    </div>
  );
}

function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }


  return (
    <>
    
      <p className='status'>Bugün indirim var!</p>
      <p className='status'>Depoyu doldurma</p>
      <p>Sohbete katılmak için giriş yapın</p>
      <button className="sign-in" onClick={signInWithGoogle}>✉️ Google ile giriş yap</button>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Çıkış</button>
  )
}

function priceStatus(){
  const status = firestore.collection('fiyat');
  const query = status.orderBy('createdAt').limit(1);

  console.log(query)
  return "artacak"
}

function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(25);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>

    <form onSubmit={sendMessage}>

      <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Mesaj yaz" />

      <button type="submit" className="submit"  disabled={!formValue}>🔼</button>

    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`}>
      <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
      <p>{text}</p>
    </div>
  </>)
}


export default App;
