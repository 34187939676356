
import React,{useEffect, useState} from "react";
import axios from "axios";
  
const Status = () => {
   
    const [status,setStatus]=useState("sabit")
    const fetchData=async()=>{
     try  {const response= axios.get("https://fullesekmi-default-rtdb.firebaseio.com/")
   
     response.data && console.log(response.data);
    }catch(e){
        console.error(e)
     }
    }
    useEffect(()=>{
        fetchData()
    },[])
    // useEffect(()=>{
// axios.get async await
// data&& setStatus(data)
    // })

    // const sendData=async()=>{
    //     axios.post()
    // }
  return (
    <div>
      <h1>
        Select option
      </h1>
      <select onChange={(e)=>setStatus(e.target.value)}>
        <option  value="artacak">artacak</option>
        <option value="sabit">sabit</option>
        <option selected value="azalacak">azalacak</option>
      </select>
    </div>
  );
};
  
export default Status;